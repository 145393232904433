<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <span class="bt rr">姓名：</span>
            <input type="text" v-model="username" class="input zczz" placeholder="请输入姓名" readonly/>
          </div>
          <div class="item">
            <span class="bt rr">证件：</span>
            <input type="text" v-model="cercode" class="input zczz" placeholder="请输入证件号码" readonly/>
          </div>
          <div class="item" v-if="this.type==='1'">
            <span class="bt rr">电话：</span>
            <input type="number" v-model="tel" class="input zczz" placeholder="请输入手机号码" readonly/>
          </div>
          <div v-if="this.type==='2'">
          <div class="item">
            <span class="bt rr">电话：</span>
            <input type="number" v-model="tel" class="input zczz" placeholder="请输入手机号码" />
          </div>
          <div class="item">
            <span class="bt">验证码：</span>
            <input type="number" v-model="code" class="input" placeholder="请输入验证码" />
            <div class="checkcode" @click="getCode">{{ time === 0?'获取验证码': '获取验证码('+time+'s)' }}</div>
          </div>
          </div>
          <div class="item">
            <span class="bt rr">备注：</span>
            <input type="text" v-model="site_memo" class="input zczz" placeholder="备注信息（选填）" />
          </div>
          
          <div class="item1">
            <img :src="zzimgurl" @click="photograph_zz" class="imgzz" />
          </div>
        </div>
        <div class="button primary mt" @click="submit">提交申请</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  async created() {
    let openid = this.$route.query.openid;
    window.sessionStorage.setItem('openid', openid);

    this.type = this.$route.query.type;
    this.unionid = this.$route.query.unionid;
    this.username = decodeURI(this.$route.query.username);
    this.certificate_code = this.$route.query.certificate_code;
    this.cercode = this.$route.query.code;
    if(this.type==='1'||this.type==='2'){
      this.tel = this.$route.query.mobile;
    }
   
    let config = (
      await this.axios.post(
        '/medicines/yd_tlService/jsapisign',
        qs.stringify({
          url: location.href
        })
      )
    ).data.data;
    window.wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.appid, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳 ${wxConfig.timestamp}
      nonceStr: config.nonce_str, // 必填，生成签名的随机串 ${wxConfig.nonceStr}
      signature: config.signature, // 必填，签名，见附录1 ${wxConfig.signature}
      jsApiList: [// 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              'checkJsApi',
							'scanQRCode',
							'getLocation', 
							'chooseImage',//拍照或从手机相册中选图接口
							'previewImage',//预览图片接口
							'uploadImage',//上传图片接口
							'downloadImage',//下载图片接口
							'getLocalImgData'
        ] 
    });

  },
  timer: '',
  data() {
    return {
      state:false,
      type: '',
      certificate_code: '',
      cercode: '',
      unionid: '',
      username: '',
      site_memo: '',
      time: 0,
      code: '',
      zzimgurl:'/img/scyyzz.png',
      pic_name: '',
      tel: ''
    };
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  methods: {
    async getCode() {
      if (this.time !== 0) {
        return;
      }
      if (!isTel(this.tel)) {
        Toast('电话号格式不正确!');
        return;
      }
      let { data } = await this.axios.post('/medicines/yd_tlService/yd_Mobile', qs.stringify({ phone: this.tel }));
      if (data.flag === '0') {
        Toast('发送成功!');
        window.clearInterval(this.timer);
        this.time = 60;
        this.timer = window.setInterval(() => {
          this.time = this.time - 1;
          if (this.time <= 0) {
            this.time = 0;
            window.clearInterval(this.timer);
          }
        }, 1000);
      } else {
        Toast(data.message);
        //Dialog({ message: data.message });
      }
    },
    photograph_zz(){
      //alert("选择照片");
      window.wx.ready(() => {
        //选择照片
        window.wx.chooseImage({
          count: 1, //张数， 默认9
          sizeType: ['compressed'], //建议压缩图
          sourceType: ['album', 'camera'], // 来源是相册(album)、相机(camera)
          //sourceType: ['camera'], // 来源是相册(album)、相机(camera)
          success: res => {
            var localIds = res.localIds.toString();

            //上传至微信服务器
            window.wx.uploadImage({
              localId: localIds, // 需要上传的图片的本地ID，由chooseImage接口获得
              success: res => {
                var serverId = res.serverId; // 返回图片的服务器端 ID         
                this.uploadImg_zz(serverId);

              },
              fail: function() {
                Toast("上传异常");
              }
            });

          },
          fail: function() {
            Toast("选择异常");
          }
        }); 
      });
      
    },
    async uploadImg_zz(serverId) {
      let { data } = await this.axios.post(
        '/medicines/yd_tlService/yd_zz_ocr',
        qs.stringify({
          pzzzid: serverId
        })
      );
      if (data.flag === '0') {
        Toast(data.message);
        /*this.zzimgurl = data.data.pic_zz;
        this.pic_name = data.data.pic_name;*/
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data; 
        this.zzimgurl = testobj.pic_zz;
        this.pic_name = testobj.pic_name;
      }else {
        Toast(data.message);
      }    
    },
    async submit() {
      if(this.state){
        return false; 
      }
      this.state = true;

      if(this.type==='2'){
        if (isEmpty(this.code)) {
          Toast('验证码不可以为空!');
          this.state = false;
          return;
        }
      }  
      
      if (isEmpty(this.zzimgurl)) {
        Toast('请正确上传营业执照!');
        this.state = false;
        return;
      }

      let { data } = await this.axios.post(
        '/medicines/yd_tlService/yd_ceartbasic',
        qs.stringify({ 
          username: this.username, 
          pic_name: this.pic_name, 
          certificate_code: this.certificate_code, 
          type: this.type, 
          unionid: this.unionid, 
          mobilecode: this.code, 
          site_memo: this.site_memo, 
          mobile: this.tel 
        })
      );
      if (data.flag === '0') {
        this.state = false;
        Dialog.confirm({
          message: "提交成功退出注册！"
        }).then(async () => {
          window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
        });
      } else {
        this.state = false;
        Toast(data.message);
        //Dialog({ message: data.message });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  margin-top: 60px;
  padding-top: 20px;
}
.container {
  padding: 0px 0px 20px 0px;
}
.bt {
  margin-left: 20px;
  &.rr {
    margin-right: 25px;
  }
}
.item1 {
  overflow: hidden;
  padding: 30px 0 28px;
  display: flex;
  position: relative;
  border-bottom: 1px solid #eee;
  .icon {
    width: 48px;
    height: 48px;
  }
  .title {
    width: 110px;
  }
  .text {
    color: #333;
  }
  .input {
    flex: 1;
    margin-left: 15px;
    border: 0;
    color: #333;
  }
  .imgzz {
    width: 460px;
    height: 460px;
    margin-left: 15px;
  }
}
.checkcode {
  position: absolute;
  line-height: 44px;
  height: 44px;
  right: 20px;
  top: 25px;
  color: rgba(0, 49, 255, 1);
}
  
</style>